<template>
    <div align="center" v-if="is_mounted">
        <br>
        <div align="center">
            <span class="text-h4">Modifica dati Cliente</span>
            <hr>
        </div>

        <div class="row justify-center q-px-md">

            <div class="col-12 col-md-4 q-mr-sm">
                <div class="text-h5" align="left">Dati anagrafici</div>
                <br>
                <q-select dense outlined v-model="dati_cliente_modificati.tipo_persona" :options="options_tipo_persona" label="Tipo di persona" class="q-mb-sm" />
                <q-input dense outlined v-model="dati_cliente_modificati.cognome" label="Cognome - Ragione sociale" class="q-mb-sm" />  
                <template v-if="dati_cliente_modificati.tipo_persona === 'persona_fisica'">
                    <q-input dense outlined v-model="dati_cliente_modificati.nome" label="Nome" class="q-mb-sm" /> 
                    <q-select dense outlined v-model="dati_cliente_modificati.sesso" :options="options_sesso" label="Sesso" class="q-mb-sm" />
                    <q-input dense outlined v-model="dati_cliente_modificati.codice_fiscale" label="Codice Fiscale" class="q-mb-sm" /> 

                    <q-input outlined
                        class="q-mb-sm"
                        v-model="dati_cliente_modificati.data_nascita"
                        mask="##/##/####"
                        dense
                        label="Data di nascita"
                    >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="dati_cliente_modificati.data_nascita"
                                    mask="DD/MM/YYYY">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>

                    <q-select class="q-mb-sm"
                        name="comune_nascita"
                        emit-value
                        dense
                        map-options
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                        outlined
                        use-input
                        input-debounce="0"
                        v-model="dati_cliente_modificati.comune_nascita"
                        :options="elenco_comuni_nascita"
                        @filter="onCambiaComuneNascita"
                        @input="cambiaComuneNascita"
                    >
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                Nessun risultato
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>

                    <q-select dense outlined v-model="dati_cliente_modificati.cap_nascita" :options="options_cap_nascita" label="Cap di nascita" class="q-mb-sm" />
                    <q-input dense outlined v-model="dati_cliente_modificati.provincia_nascita" label="Provincia nascita" disable class="q-mb-sm" /> 
                </template> 

                <template v-if="dati_cliente_modificati.tipo_persona === 'professionista'">
                    <q-input dense outlined v-model="dati_cliente_modificati.nome" label="Nome" class="q-mb-sm" /> 
                </template>

                <template v-if="dati_cliente_modificati.tipo_persona !== 'persona_fisica'">
                    <q-input dense outlined v-model="dati_cliente_modificati.partita_iva" label="Partita IVA" class="q-mb-sm" /> 
                    <q-input dense outlined v-model="dati_cliente_modificati.codice_univoco_destinatario" label="Codice Univoco Destinatario" class="q-mb-sm" /> 
                </template>

                <q-input dense outlined v-model="dati_cliente_modificati.professione" label="Professione" class="q-mb-sm" /> 
                <q-select dense outlined emit-value map-options v-model="dati_cliente_modificati.esente_obbligo_pec" :options="options_si_no" label="Esente obbligo PEC" class="q-mb-sm" /> 
            </div>

            <div class="col-12 col-md-4 q-mr-sm">
                <div class="text-h5" align="left">Indirizzo di residenza / Sede</div>
                <br>
                <q-input dense outlined v-model="dati_cliente_modificati.indirizzo_residenza" label="Indirizzo" class="q-mb-sm" />
                <q-select class="q-mb-sm"
                        name="comune_residenza"
                        emit-value
                        dense
                        map-options
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                        outlined
                        use-input
                        input-debounce="0"
                        v-model="dati_cliente_modificati.comune_residenza"
                        :options="elenco_comuni_residenza"
                        @filter="onCambiaComuneResidenza"
                        @input="cambiaComuneResidenza"
                    >
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                Nessun risultato
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                    <q-select dense outlined v-model="dati_cliente_modificati.cap_residenza" :options="options_cap_residenza" label="Cap" class="q-mb-sm" />
                    <q-input dense outlined v-model="dati_cliente_modificati.provincia_residenza" label="Provincia" disable class="q-mb-sm" /> 
            </div>

            <div class="col-12 col-md-4 q-mr-sm">
                <div class="text-h5" align="left">Indirizzo recapiti</div>
                <br>
                <q-input dense outlined v-model="dati_cliente_modificati.indirizzo_recapito" label="Indirizzo" class="q-mb-sm" />
                <q-select class="q-mb-sm"
                        name="comune_recapito"
                        emit-value
                        dense
                        map-options
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                        outlined
                        use-input
                        input-debounce="0"
                        v-model="dati_cliente_modificati.comune_recapito"
                        :options="elenco_comuni_recapito"
                        @filter="onCambiaComuneRecapito"
                        @input="cambiaComuneRecapito"
                    >
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                Nessun risultato
                                </q-item-section>
                            </q-item>
                        </template>
                </q-select>
                <q-select dense outlined v-model="dati_cliente_modificati.cap_recapito" :options="options_cap_recapito" label="Cap" class="q-mb-sm" />
                <q-input dense outlined v-model="dati_cliente_modificati.provincia_recapito" label="Provincia" disable class="q-mb-sm" /> 
            </div>

            <div class="col-12 col-md-4 q-mr-sm">
                <div class="text-h5" align="left">Altri dati</div>
                <br>
                <q-input dense outlined v-model="dati_cliente_modificati.telefono" label="Numero di telefono" class="q-mb-sm" /> 
                <q-input dense outlined v-model="dati_cliente_modificati.cellulare" label="Numero di cellulare" class="q-mb-sm" /> 
                <q-input dense outlined v-model="dati_cliente_modificati.email" label="Indirizzo EMAIL" class="q-mb-sm" /> 
                <q-input dense outlined v-model="dati_cliente_modificati.pec" label="Indirizzo PEC" class="q-mb-sm" /> 
            </div>
        
        </div>

        <div align="center">
            <hr>
            
            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="ANNULLA MODIFICHE" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="REGISTRA"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onRegistraClicked()"
                    />
                </div>
            </div>

            <br><br><br><br><br><br>
        </div>
    </div>
</template>

<script>

import { mapFields} from 'vuex-map-fields';
import { mapState,mapActions } from 'vuex';
import commonLib from "@/libs/commonLib";
import QQButton from "@/components/QQButton.vue";

export default {
    name: "ModificaDatiCliente",
    components: {
        QQButton
    },
    data() {
        return {
            is_loading: true,
            dati_cliente_modificati: {},
            is_mounted: false,
            elenco_comuni_nascita: [],
            elenco_comuni_residenza: [],
            elenco_comuni_recapito: [],
            options_tipo_persona: [
                {"label": "Persona fisica", "value": "persona_fisica"},
                {"label": "Professionista con P.Iva", "value": "professionista"},
                {"label": "Società o Ente", "value": "societa"}
            ],
            options_sesso: [
                {"label": "Maschile", "value": "maschile"},
                {"label": "Femminile", "value": "femminile"},
            ],
            options_si_no: [
                {label: "Si", value: "1"},
                {label: "No", value: "0"}
            ],
            options_cap_nascita: [],
            options_cap_residenza: [],
            options_cap_recapito: []
        }
    },
    methods: {
        ...mapActions({
            //popolaProvinceEStati: "formPreventivo/popolaProvinceEStati",
            getGeoComuniForProvincia: "formPreventivo/getGeoComuniForProvincia",

            getGeoComuniForName: "formPreventivo/getGeoComuniForName",
            getGeoCapForComune: "formPreventivo/getGeoCapForComune",
            getStatiFormField: "formPreventivo/getGeoStatiForFormField",
            getProvinciaFromComune: "formPreventivo/getProvinciaFromComune",
            RegistraDatiClienteFattura: "gestioneFatture/RegistraDatiClienteFattura"
        }),
        async onMapComuni(comuni) {
            if (commonLib.isEmpty(comuni)) return [];

            return comuni.map(comune => {
                let nome = comune.nome.trim();
                return {
                    //is_visible_if:"",
                    label: nome,
                    value: nome
                };
            });
        },
        async onMapCap(cap) {
            if (commonLib.isEmpty(cap)) return [];

            return await cap.map(c => {
                return {
                    is_visible_if:"",
                    label: c.trim(),
                    value: c.trim()
                };
            });
        },
        async cambiaComuneNascita(input_value) {
            var comune = input_value;

            if (commonLib.isEmpty(comune)) return;

            var elenco = (this.tutti_i_comuni.filter(c => {
                var len = comune.length;
                var start_with = c.nome.substring(0,len).toLowerCase();
                return start_with === comune.toLowerCase();
            }));

            this.elenco_comuni_nascita = await this.onMapComuni(elenco);
            var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                return comune.nome.toLowerCase() === input_value.toLowerCase();
            });

            if (!commonLib.isEmpty(comune_selezionato[0])) {
                this.dati_cliente_modificati.provincia_nascita = comune_selezionato[0].sigla_provincia;
                this.options_cap_nascita = await this.onMapCap(comune_selezionato[0].cap);
                this.dati_cliente_modificati.cap_nascita = comune_selezionato[0].cap[0];
            }
                
        },
        async cambiaComuneResidenza(input_value) {
            var comune = input_value;

            if (commonLib.isEmpty(comune)) return;

            var elenco = (this.tutti_i_comuni.filter(c => {
                var len = comune.length;
                var start_with = c.nome.substring(0,len).toLowerCase();
                return start_with === comune.toLowerCase();
            }));

            this.elenco_comuni_residenza = await this.onMapComuni(elenco);
            var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                return comune.nome.toLowerCase() === input_value.toLowerCase();
            });

            if (!commonLib.isEmpty(comune_selezionato[0])) {
                this.dati_cliente_modificati.provincia_residenza = comune_selezionato[0].sigla_provincia;
                this.options_cap_residenza = await this.onMapCap(comune_selezionato[0].cap);
                this.dati_cliente_modificati.cap_residenza = comune_selezionato[0].cap[0];
            }
                
        },
        async cambiaComuneRecapito(input_value) {
            var comune = input_value;

            if (commonLib.isEmpty(comune)) return;

            var elenco = (this.tutti_i_comuni.filter(c => {
                var len = comune.length;
                var start_with = c.nome.substring(0,len).toLowerCase();
                return start_with === comune.toLowerCase();
            }));

            this.elenco_comuni_recapito = await this.onMapComuni(elenco);
            var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                return comune.nome.toLowerCase() === input_value.toLowerCase();
            });

            if (!commonLib.isEmpty(comune_selezionato[0])) {
                this.dati_cliente_modificati.provincia_recapito = comune_selezionato[0].sigla_provincia;
                this.options_cap_recapito = await this.onMapCap(comune_selezionato[0].cap);
                this.dati_cliente_modificati.cap_recapito = comune_selezionato[0].cap[0];
            }
                
        },
        async onCambiaComuneNascita(input_value,update) {
            
            if (input_value !== "") {
                update(async ()=>{
                    await this.cambiaComuneNascita(input_value);
                });
            }
        },
        async onCambiaComuneResidenza(input_value,update) {
            
            if (input_value !== "") {
                update(async ()=>{
                    await this.cambiaComuneResidenza(input_value);
                });
            }
        },
        async onCambiaComuneRecapito(input_value,update) {
            
            if (input_value !== "") {
                update(async ()=>{
                    await this.cambiaComuneReapito(input_value);
                });
            }
        },

        async onRegistraClicked() {
            this.$q.dialog({
                title: 'Attenzione',
                html: true,
                message: "Procedo con la modifica dei dati del Cliente?",
                cancel: {
                    focus: true,
                    flat: false,
                    label: "No",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si, procedi"
                },
            }).onOk(async () => {

                console.log("dati cliente modificati:",this.dati_cliente_modificati);
                // Aggiusta il formato del campo Esente Obbligo PEC xche esce come oggetto invece che come valore
                if (typeof(this.dati_cliente_modificati.esente_obbligo_pec) === "object") this.dati_cliente_modificati.esente_obbligo_pec = this.dati_cliente_modificati.esente_obbligo_pec.value;

                // Aggiusta il valore stringa per inserirlo numerico
                switch (this.dati_cliente_modificati.esente_obbligo_pec) {
                    case "No": this.dati_cliente_modificati.esente_obbligo_pec = 0; break;
                    case "Si": this.dati_cliente_modificati.esente_obbligo_pec = 1; break;
                }

                await this.RegistraDatiClienteFattura({
                    guidPratica: this.guid_pratica,
                    id_contratto: this.contratto.idcontratto,
                    dati_cliente: this.dati_cliente_modificati
                });

                this.cliente = this.dati_cliente_modificati;

                this.$router.push({name : "Fatture.SchedaFattura"});
            });
            
        },
        onIndietroClicked() {
            this.$router.push({name : "Fatture.SchedaFattura"});
        }
    },
    computed: {
        ...mapState({
            tutti_i_comuni: state => state.geoStore.comuni
        }),
        ...mapFields("gestioneFatture", {
            cliente: "cliente",
            pratica: "pratica",
            contratto: "contratto",
            guid_pratica: "guid_pratica"
        })
        
    },
    async mounted() {
        await this.$store.dispatch("geoStore/initComuni");
        this.dati_cliente_modificati = this.cliente;
        this.elenco_comuni_nascita = [];
        this.elenco_comuni_residenza = [];
        this.guid_pratica = this.pratica.numero;

        // await this.popolaProvinceEStati();
        switch (this.dati_cliente_modificati.esente_obbligo_pec) {
            case 0: this.dati_cliente_modificati.esente_obbligo_pec = "No"; break;
            case 1: this.dati_cliente_modificati.esente_obbligo_pec = "Si"; break;
        }

        //console.log("cliente: ",this.cliente);
        //console.log("tutti i comune:",this.tutti_i_comuni);
        //console.log("guid pratica:",this.guid_pratica);

        this.is_mounted = true;
    }
}

</script>